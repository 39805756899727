import { Field, Type, deserialize } from 'serialize-ts';
import { Tag } from 'react-tag-autocomplete';
import AttrType, { AttrTypeSerializer } from './AttrType';

export default class Attr {
    @Field()
    @Type(Number)
    attributeId: number = 0;

    @Field()
    @Type(String)
    name: string;

    @Field()
    @Type(new AttrTypeSerializer())
    type: AttrType;

    toTag(): Tag {
        return {
            id: this.attributeId,
            name: `${ this.type.displayName }: ${ this.name }`,
            disabled: false
        };
    }
}

async function getAllAttributes(): Promise<Attr[]> {
    var attrsResponse = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/attributes`, { method: "GET" });
    var attrsJson = await attrsResponse.json();
    return attrsJson.map(jAttr => deserialize(jAttr, Attr));
}

export class AttrConsts {
    public static allAttributes: Attr[] = [];
    public static allFabricLines: Attr[] = [];

    static getAttribute(id: number) {
        var attr = this.allAttributes.find(a => a.attributeId === id);
        return (!attr ? null : attr) as Attr;
    }

    static getAttributes(type: AttrType = null) {
        return !type ? this.allAttributes : this.allAttributes.filter(a => a.type === type);
    }

    static getAttributeTags(type: AttrType = null) {
        return AttrConsts.getAttributes(type).map(a => a.toTag());
    }

    static async refreshConsts(): Promise<void> {
        try {
            AttrConsts.allAttributes = await getAllAttributes();
            AttrConsts.allFabricLines = AttrConsts.allAttributes.filter(a => a.type === AttrType.fabricLine);
        } catch (err) {
            console.error(err);
        }
    }
}