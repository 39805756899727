import React from 'react';
import SearchBox from './components/Swatches/SearchBox';
import RecommendationCarousel from './components/previews/RecommendationCarousel';
import OutfitPreview from './components/previews/OutfitPreview';
import Fabric, { refreshFabrics } from './components/modules/Fabrics';
import PerOutfitItemFabrics from "./components/modules/PerOutfitItemFabrics";
import Attr, { AttrConsts } from './components/modules/Attr';
import OutfitItem, { outfitItems, getName, getKeyName } from './components/modules/OutfitItem';
import PerOutfitItemData from "./components/modules/PerOutfitItemData";
import { deserialize } from 'serialize-ts';
import { TextureLoader } from 'three';
import { Button } from "reactstrap";
import AlertModal from './components/AlertModal';
import ConfirmModal from './components/ConfirmModal';
import WaterMark from './assets/images/watermark.png';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/App.css';
import { Tag } from 'react-tag-autocomplete';
import AddEditModal from './components/AddEditModal';
import { refreshRules } from './components/modules/Rules';
import LoginModal from './components/LoginModal';

class App extends React.Component<{}, AppState> {
    constructor(props: {}, state: AppState) {
        super(props, state);

        this.state = new AppState();
        this.getRecommendations = this.getRecommendations.bind(this);
        this.handlePantsMustMatchJacket = this.handlePantsMustMatchJacket.bind(this);
        this.setViewModeToLarge = this.setViewModeToLarge.bind(this);
        this.resetCamera = this.resetCamera.bind(this);
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.toggleAlertModal = this.toggleAlertModal.bind(this);
        this.handlefabricLineChange = this.handlefabricLineChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    largeMannequinViewRef: OutfitPreview;
    recommendationsRef: RecommendationCarousel;

    toggleAlertModal(msg: string | Error | null = null, type: string = "Alert") {
        if (this.state.alertModalType !== "Alert")
            this.setState({ alertModalIsOpen: false });

        var msgString = "";
        if (!!msg)
            msgString = typeof msg == 'string' ? msg : msg.toString();
        this.setState({ modalText: msgString, alertModalType: type });

        if (this.state.alertModalType === "Alert")
            this.setState({ alertModalIsOpen: !this.state.alertModalIsOpen });
    };

    async componentDidMount() {
        Promise.all([ AttrConsts.refreshConsts(), refreshFabrics(), refreshRules() ])
            .then(() => this.setState({ isPreloading: false, enabledFabricLines: [ AttrConsts.allFabricLines[ AttrConsts.allFabricLines.length - 1 ] ] }));
    }

    toggleAddModal() {
        if (!this.state.loginState) {
            this.setState({ loginModalIsOpen: true });
            return;
        }
        this.setState({ addModalIsOpen: !this.state.addModalIsOpen });
    }

    async getRecommendations() {
        this.setState({ viewMode: "Recommendation", recommendationLoading: true });
        this.resetCamera();

        // Build the body for the new reccomendation request
        var attrs: any[] = [];
        for (var [ FilterOutfitItem, Fabrics ] of this.state.selectedFabrics) {
            if (this.state.pantsMustMatchJacket && FilterOutfitItem === OutfitItem.Pants)
                continue;

            var allowedFabricIds = [];
            if (this.state.viewFabrics.get(FilterOutfitItem)) //selected fabrics exist
                allowedFabricIds = [ this.state.viewFabrics.get(FilterOutfitItem).id ];
            else if (Fabrics.length !== 0) //Selected fabrics doesn't exist, but fabrics do
                allowedFabricIds = Fabrics.map(f => f.id);

            attrs.push({ Part: FilterOutfitItem, allowedFabricIds, requiredAttributeIds: this.state.enabledFabricLines.map(a => a.attributeId) });
        }

        const noRecommendationsMessage = "Recommendation rules did not find any outfits given the selected fabric line, and fabrics. Try selecting other fabrics if any are selected, changing the fabric lines allowed, or allowing sold out fabrics.";
        try {
            var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/recommendations/${ process.env.REACT_APP_RECOMMENDATION_COUNT }?includeSoldOut=${ this.state.includeSoldOut }&pantsMustMatchJacket=${ this.state.pantsMustMatchJacket }`, {
                method: "POST",
                body: JSON.stringify(attrs),
                headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
            });
            var json = await response.json();
        } catch (err) {
            this.toggleAlertModal(JSON.stringify(err).indexOf("Unexpected end of JSON input") === -1 || JSON.stringify(err).indexOf("JSON.parse") === -1 ? noRecommendationsMessage : err.toString());
            this.setState({ recommendationLoading: false });
            return;
        }

        // Get all recommendation image
        try {
            var recommendations: PerOutfitItemFabrics[] = [];
            for (let recJson of json) {
                var rec = new PerOutfitItemFabrics();
                for (var oi of outfitItems) {
                    if (!recJson.hasOwnProperty(getKeyName(oi))) {
                        console.error(`No ${ getName(oi) } in reccomendation`);
                        continue;
                    }
                    rec.set(oi, !!recJson[ getKeyName(oi) ] ? deserialize(recJson[ getKeyName(oi) ], Fabric) : null);
                }
                recommendations.push(rec);
            };

            if (!this.recommendationsRef)
                return;

            try {
                this.recommendationsRef.setRecommendations(recommendations);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            console.error(err);
            this.toggleAlertModal(noRecommendationsMessage);
        } finally {
            this.setState({ recommendationLoading: false });
        }
    }

    async setViewModeToLarge() {
        this.setState({ viewMode: "Large", viewLoading: true });
        try {
            var recommendation = new PerOutfitItemFabrics();
            for (var oi of outfitItems)
                recommendation.set(oi, this.state.viewFabrics.get(oi));
            if (this.state.pantsMustMatchJacket && this.state.viewFabrics.get(OutfitItem.Jacket))
                recommendation.set(OutfitItem.Pants, this.state.viewFabrics.get(OutfitItem.Jacket));
            else if (this.state.viewFabrics.get(OutfitItem.Pants))
                recommendation.set(OutfitItem.Pants, this.state.viewFabrics.get(OutfitItem.Pants));

            this.largeMannequinViewRef.setRecommendation(recommendation);
        } catch (err) {
            console.error(err);
        }
        this.setState({ viewLoading: false });
    }

    resetCamera() {
        var ref = null;
        if (this.largeMannequinViewRef && this.state.viewMode === "Large")
            ref = this.largeMannequinViewRef;
        else if (this.recommendationsRef && this.state.viewMode === "Recommendation")
            ref = this.recommendationsRef;
        else
            return;

        try {
            ref.resetCamera();
        } catch (err) {
            console.error(err);
        }
    }

    handlefabricLineChange(lineId: number, enabled: boolean) {
        var newLines = this.state.enabledFabricLines.filter(a => a.attributeId !== lineId);
        if (enabled)
            newLines.push(AttrConsts.allFabricLines.find(a => a.attributeId === lineId));
        this.setState({ enabledFabricLines: newLines });
    }

    async promisifyLoadTexture(path: string): Promise<THREE.Texture> {
        var loader = new TextureLoader();
        return new Promise<THREE.Texture>((resolve, reject) =>
            loader.load(path, texture => {
                try {
                    resolve(texture);
                } catch (ex) {
                    reject(ex);
                }
            })
        );
    }

    swatchChange(si: OutfitItem, fabric: Fabric) {
        const viewFabrics = this.state.viewFabrics;
        viewFabrics.set(si, fabric);
        this.setState({ viewFabrics });
    }

    // Force jacket fabric to match pants fabric
    handlePantsMustMatchJacket(toggle: boolean) {
        this.setState({ pantsMustMatchJacket: toggle });
    }

    PantsSearchBox: SearchBox;
    ShoesSearchBox: SearchBox;
    ShirtSearchBox: SearchBox;
    JacketSearchBox: SearchBox;

    getAllSearchBoxes(): SearchBox[] {
        return outfitItems.map(oi => this[ `${ oi }SearchBox` ]).filter(oi => !!oi);
    }

    render() {
        if (this.state.isPreloading)
            return <h2> LOADING... </h2>;

        return (
            <div className="container-fluid h-100">
                <AddEditModal
                    isOpen={this.state.addModalIsOpen}
                    toggle={this.toggleAddModal}
                    toggleAlertModal={(msg, type = "Alert") => this.toggleAlertModal(msg, type)}
                    getConfirmation={cb => this.setState({ confirmationCallback: cb, confirmModalIsOpen: true })}
                    zoom={this.state.zoom}
                    itemsPerPage={100}
                    waitingForPreload={this.state.isPreloading}
                    onFabricChanges={() => this.getAllSearchBoxes().forEach(s => s.refilterFabrics())} />

                <AlertModal
                    policyAlertModal={this.state.alertModalIsOpen}
                    toggle={this.toggleAlertModal}
                    modalText={this.state.modalText}
                    modalType={this.state.alertModalType} />

                <ConfirmModal
                    isVisible={this.state.confirmModalIsOpen}
                    toggle={() => this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen })}
                    onDecision={this.state.confirmationCallback} />

                <LoginModal
                    isVisible={this.state.loginModalIsOpen}
                    toggleAlertModal={this.toggleAlertModal}
                    toggle={() => this.setState({ loginModalIsOpen: !this.state.loginModalIsOpen })}
                    onLogin={() => this.setState({ loginState: true }, () => this.setState({ addModalIsOpen: true }))} />

                <div className="row fullHeight">
                    <div className="col-9 fullHeight p-0" style={this.state.viewLoading ? { display: "none" } : {}}>
                        <div className="row fullHeight m-0" style={ this.state.recommendationLoading ? { display: "none" } : {}}>
                            <OutfitPreview style={this.state.viewMode !== "Large" ? { display: "none" } : {}} type="View" ref={ref => this.largeMannequinViewRef = ref} showFabricNames={true} showSaveScreenshot={true} showToggleImages={true} />
                            <RecommendationCarousel ref={ref => this.recommendationsRef = ref} style={this.state.viewMode !== "Recommendation" ? { display: "none" } : {}}/>
                            <img className="watermarkimg" src={WaterMark} alt="LGFG Logo"></img>
                        </div>
                        <div className="lds-circle" style={ this.state.recommendationLoading ? {} : { display: "none" }}><div></div></div>
                    </div>
                    <div className="col-3 filter">
                        <div className="row filterRow">
                            <h5 className="page-title mx-auto">Fabric Lines</h5>
                            <div className="row checkBoxsRow buttonDiv">
                                {
                                    AttrConsts.allFabricLines.map(fabricLineAttr =>
                                        <div className="col-12 pl-1" key={"fabricLine" + fabricLineAttr.attributeId}>
                                            <input className="checkbox" type="checkbox" name={fabricLineAttr.name || ''} key={"FabricLine" + fabricLineAttr.name}
                                                checked={this.state.enabledFabricLines.some(aa => aa.attributeId === fabricLineAttr.attributeId)}
                                                onChange={e => this.handlefabricLineChange(fabricLineAttr.attributeId, e.target.checked)} /> {fabricLineAttr.name}
                                        </div>)
                                }
                            </div>
                            <h5 className="page-title mx-auto">Outfit Type</h5>
                            <div className="buttonDiv row">
                                <div className="col-8">
                                    <input className="checkbox" type="radio" value="false" name="comboCheckBox" checked={!this.state.pantsMustMatchJacket}
                                        onChange={e => this.handlePantsMustMatchJacket(!e.target.checked)} />{' '}
                                    Jacket / Pants Combo
                                </div>
                                <div className="col-4">
                                    <input className="checkbox" type="radio" value="true" name="suitCheckBox" checked={this.state.pantsMustMatchJacket}
                                        onChange={e => this.handlePantsMustMatchJacket(e.target.checked)} />{' '}
                                    Suit
                                </div>
                            </div>
                            <div className="buttonDiv">
                                <input className="checkbox" type="checkbox" name="inOrOut" checked={this.state.includeSoldOut} onChange={e => this.setState({ includeSoldOut: e.target.checked })} />
                                Include Sold out
                            </div>
                            {
                                outfitItems
                                    .filter(oi => !this.state.pantsMustMatchJacket || oi !== OutfitItem.Pants)
                                    .map(oi =>
                                        <SearchBox key={"Searchbox" + getName(oi)}
                                            ref={ref => this[ `${ oi }SearchBox` ] = ref}
                                            includeSoldOut={this.state.includeSoldOut}
                                            allowedFabricLines={this.state.enabledFabricLines}
                                            onSelectionChanged={fabric => this.swatchChange(oi, fabric)}
                                            outfitItem={oi}
                                            zoom={this.state.zoom}
                                            waitingForPreload={this.state.isPreloading}
                                            itemsPerPage={100} />)
                            }
                            <h5 className="page-title mx-auto">Tile zoom</h5>
                            <div className="col-12 d-flex p-0">
                                <input
                                    type="range" name="updateFabricScaleSlider"
                                    onChange={event => this.setState({ zoom: +event.target.value })}
                                    className={"custom-range d-inline-block w-60 pl-3"}
                                    value={this.state.zoom} min={100} max={1000} step={5} />
                            </div>
                            <div className="buttonDiv">
                                <Button className="recommendationbtn" color="secondary" onClick={this.getRecommendations}> Shown New Recommendations </Button>
                            </div>
                            <div className="buttonDiv">
                                <Button className="viewbtn" color="secondary" onClick={this.setViewModeToLarge}> View </Button>
                                <Button className="addbtn" color="secondary" onClick={this.toggleAddModal}> Add / Edit </Button>
                            </div>
                        </div>
                    </div>
                    <Button className="camerabtn" color="secondary" onClick={this.resetCamera}> Reset Camera</Button>
                </div>
            </div>
        );
    }
}

class AppState {
    enabledFabricLines: Attr[] = [];
    fabricTags: Tag[] = [];
    selectedFabrics: PerOutfitItemData<Fabric[]> = PerOutfitItemData.withDefaultValue([] as Fabric[]);
    pantsMustMatchJacket: boolean = false;
    includeSoldOut: boolean = false;
    zoom: number = 100;
    isPreloading: boolean = true;

    addModalIsOpen: boolean = false; // TODO Change back to false
    alertModalIsOpen: boolean = false;
    confirmModalIsOpen: boolean = false;

    isRecommendationOpen: boolean = true;
    recommendationLoading: boolean = false;
    viewLoading: boolean = false;
    viewMode: "Large" | "Recommendation" = "Large";
    viewFabrics: PerOutfitItemFabrics = new PerOutfitItemFabrics();
    modalText: string = "";
    alertModalType: string = "Alert";
    confirmationCallback: (decision: boolean) => void;
    loginState: boolean = false; // TODO Change back to false
    loginModalIsOpen: boolean = false;
}

export default App;