import React from 'react';
import OutfitPreview from '../previews/OutfitPreview';
import Attr, { AttrConsts } from '../modules/Attr';
import AttrType from "../modules/AttrType";
import Fabric, { getStoredFabrics, refreshFabrics } from '../modules/Fabrics';
import { Button } from "reactstrap";
import AttributeDropdownEditor from './AttributeDropdownEditor';
import OutfitItem, { getFabricNumberPhrasing, lowInfoFabricItems } from '../modules/OutfitItem';
import imageType from 'image-type';
import https from 'https';
import { IncomingMessage } from 'http';
import PerOutfitItemFabrics from '../modules/PerOutfitItemFabrics';

export default class EditFabricForm extends React.Component<EditFabricFormProps, EditFabricFormState> {
    constructor(props: EditFabricFormProps, state: EditFabricFormState) {
        super(props, state);
        this.state = new EditFabricFormState(props.fabric);
        this.fabricNumberIsValid = this.fabricNumberIsValid.bind(this);
    }

    static defaultProps = { fabric: null };
    modalMannequin: OutfitPreview;

    componentWillReceiveProps(nextProps: EditFabricFormProps) {
        if (nextProps.fabric && (!this.props.fabric || nextProps.fabric.id !== this.props.fabric.id))
            this.setState(new EditFabricFormState(nextProps.fabric));
    }

    async refreshPreview(): Promise<void> {
        if (!this.previewIsRenderable())
            return;

        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var response = await new Promise<IncomingMessage>((resolve, reject) =>
            https.get(proxyurl + this.state.fabricUrl, response => {
                response.on('readable', () => resolve(response));
                response.on("error", () => reject());
            })
        );

        try {
            const chunk = response.read(imageType.minimumBytes);
            response.destroy();
            try {
                var fabricUrlIsValid = !!imageType(chunk);
            } catch (err) {
                throw err;
            }
            if (fabricUrlIsValid)
                this.updateModelOutfit().then(() => this.modalMannequin.resetCamera());
        } catch (err) {
            console.error(err);
        }
    }

    async updateModelOutfit() {
        this.setState({ viewLoading: true });
        await refreshFabrics();
        const matchingFabrics = getStoredFabrics().filter(f => f.isTemp && (f.url === this.state.fabricUrl || f.appliesTo === this.state.fabricAppliesTo || f.scale === this.state.fabricScale));
        var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/fabrics` + (matchingFabrics.length === 0 ? "" : `/${ matchingFabrics[ 0 ].id }`),
            {
                method: matchingFabrics.length === 0 ? "POST" : "PATCH",
                body: JSON.stringify({
                    "id": 0,
                    "soldOut": false,
                    "url": this.state.fabricUrl,
                    "number": "TEMP",
                    "appliesTo": this.state.fabricAppliesTo,
                    "scale": this.state.fabricScale,
                    "isTemp": true
                }),
                headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
            });

        if (!response.ok) {
            this.props.toggleAlertModal("Check Again.");
            this.setState({ viewLoading: false });
            return;
        }

        var fabricId = matchingFabrics.length === 0 ? await response.json() : matchingFabrics[ 0 ].id;
        this.showMannequin(fabricId);
        this.setState({ viewLoading: false });
    }

    async showMannequin(fabricId: number) {
        this.setState({ viewLoading: true });
        try {
            var recommendation = PerOutfitItemFabrics.createInstanceForSingleItemPreview(this.state.fabricAppliesTo, this.getFabric(fabricId));
            this.modalMannequin.setRecommendation(recommendation);
        } catch (err) {
            console.error(err);
        }
        this.setState({ viewLoading: false });
    }

    fabricScaleIsValid(): boolean {
        return this.state.fabricScale > 0.001 && this.state.fabricScale <= 5;
    }

    previewIsRenderable(): boolean {
        return this.fabricScaleIsValid() && this.fabricScaleIsValid() && this.state.fabricAppliesTo !== OutfitItem.None;
    }

    fabricIsValid(): boolean {
        return this.fabricNumberIsValid()
            && this.fabricScaleIsValid()
            && this.state.fabricAppliesTo !== OutfitItem.None
            && (!!this.state.material || lowInfoFabricItems.includes(this.state.fabricAppliesTo))
            && (!!this.state.fabricLine || lowInfoFabricItems.includes(this.state.fabricAppliesTo))
            && (!!this.state.primaryColor || lowInfoFabricItems.includes(this.state.fabricAppliesTo) || this.state.primaryPattern.name.toLowerCase() === "fancy")
            && (!!this.state.primaryPattern || lowInfoFabricItems.includes(this.state.fabricAppliesTo));
    }

    getFabric(id: number = -1): Fabric {
        var f = new Fabric();
        if (!!this.props.fabric && id === -1)
            f.id = this.props.fabric.id;
        if (id !== -1)
            f.id = id;
        f.number = this.state.fabricNumber;
        f.isTemp = false;
        f.url = this.state.fabricUrl;
        f.appliesTo = this.state.fabricAppliesTo;
        f.scale = this.state.fabricScale;
        f.soldOut = this.state.soldOut;
        if (!!this.state.material)
            f.materialId = this.state.material.attributeId;
        if (!!this.state.fabricLine)
            f.fabricLineId = this.state.fabricLine.attributeId;
        if (!!this.state.primaryColor)
            f.primaryColorId = this.state.primaryColor.attributeId;
        if (!!this.state.primaryPattern)
            f.primaryPatternId = this.state.primaryPattern.attributeId;
        if (!!this.state.secondaryColor)
            f.secondaryColorId = this.state.secondaryColor.attributeId;
        if (!!this.state.secondaryPattern)
            f.secondaryPatternId = this.state.secondaryPattern.attributeId;
        return f;
    }

    fabricNumberIsValid(): boolean {
        if (!this.state.fabricNumber || this.state.fabricNumber.length === 0)
            return false;
        var collisionFabrics = getStoredFabrics().filter(f => !f.isTemp && (f.number === this.state.fabricNumber));
        return this.props.fabric ? !collisionFabrics.some(f => f.id !== this.props.fabric.id) : collisionFabrics.length === 0;
    }

    static urlRegex: RegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

    static stringIsUrl(str: string) {
        if (!str || str.length === 0)
            return false;

        const regexResults = EditFabricForm.urlRegex.exec(str);
        return !!regexResults && regexResults.some(r => !!r && r.length === str.length);
    }

    render() {
        return (
            <div className="row m-0 col-12">
                {this.props.children}
                <div className="col-6">
                    <div className="col-12 pb-2">
                        <input type="checkbox" name="soldout" checked={this.state.soldOut} onChange={e => this.setState({ soldOut: e.target.checked })} /> Sold out
                    </div>
                    <div className="col-12 pb-2">
                        Image URL
                        <input type="text" name="fabricUrl"
                            className={"form-control " + (this.state.fabricUrl && EditFabricForm.stringIsUrl(this.state.fabricUrl) ? "" : "errorValue")} value={this.state.fabricUrl}
                            onKeyDown={e => { if (e.keyCode === 13 || e.keyCode === 9 || e.keyCode === 27) this.refreshPreview(); }}
                            onChange={e => this.setState({ fabricUrl: e.target.value })}
                            onBlur={() => { if (this.state.fabricUrl !== this.state.previousFabricUrl) this.setState({ previousFabricUrl: this.state.fabricUrl }, this.refreshPreview); }}
                            onFocus={() => this.setState({ previousFabricUrl: this.state.fabricUrl })} required />
                    </div>
                    <div className="col-12 pb-2">
                        {getFabricNumberPhrasing(this.state.fabricAppliesTo)}
                        <input type="text" name="fabricNumber" className={"form-control " + (this.fabricNumberIsValid() ? "" : "errorValue")} value={this.state.fabricNumber} onChange={e => this.setState({ fabricNumber: e.target.value })} required />
                    </div>
                    <div className="col-12">
                        Applies To
                        <select name="appliesToItem" className={"form-control " + (this.state.fabricAppliesTo === OutfitItem.None ? "errorValue" : "")} value={this.state.fabricAppliesTo}
                            onChange={e => this.setState({ fabricAppliesTo: Number(e.target.value) }, this.refreshPreview)}>
                            <option value={0}>...</option>
                            <option value={3}>Suit</option>
                            <option value={4}>Shirt</option>
                            <option value={8}>Shoes</option>
                            <option value={32}>Shoes (Image)</option>
                            <option value={64}>Tie (Image)</option>
                            <option value={128}>Watch (Image)</option>
                            <option value={256}>Bag</option>
                            <option value={512}>Accessory</option>
                        </select>
                    </div>
                    <div className="col-12">
                        Fabric Scale
                    </div>
                    <div className="col-12">
                        <input type="range" name="fabricScaleSlider" onChange={e => this.setState({ fabricScale: e.target.valueAsNumber })} onMouseUp={e => this.refreshPreview()} className="custom-range" value={this.state.fabricScale} min={0.01} max={3} step={0.025} />
                    </div>
                    <AttributeDropdownEditor
                        type={AttrType.primaryColor}
                        onChange={a => this.setState({ primaryColor: a })}
                        waitingForPreload={this.props.waitingForPreload}
                        defaultValue={this.state.primaryColor}
                        isValid={(this.state.primaryPattern && this.state.primaryPattern.name.toLowerCase() === "fancy") || lowInfoFabricItems.includes(this.state.fabricAppliesTo) || !!this.state.primaryColor} />
                    <AttributeDropdownEditor
                        type={AttrType.primaryPattern}
                        onChange={a => this.setState({ primaryPattern: a })}
                        waitingForPreload={this.props.waitingForPreload}
                        defaultValue={this.state.primaryPattern}
                        isValid={!!this.state.primaryPattern || lowInfoFabricItems.includes(this.state.fabricAppliesTo)} />
                    <AttributeDropdownEditor
                        type={AttrType.secondaryColor}
                        onChange={a => this.setState({ secondaryColor: a })}
                        defaultValue={this.state.secondaryColor}
                        waitingForPreload={this.props.waitingForPreload} />
                    <AttributeDropdownEditor
                        type={AttrType.secondaryPattern}
                        onChange={a => this.setState({ secondaryPattern: a })}
                        defaultValue={this.state.secondaryPattern}
                        waitingForPreload={this.props.waitingForPreload} />
                    <AttributeDropdownEditor
                        type={AttrType.material}
                        onChange={a => this.setState({ material: a })}
                        waitingForPreload={this.props.waitingForPreload}
                        defaultValue={this.state.material}
                        isValid={!!this.state.material || lowInfoFabricItems.includes(this.state.fabricAppliesTo)} />
                    <AttributeDropdownEditor
                        type={AttrType.fabricLine}
                        onChange={a => this.setState({ fabricLine: a })}
                        waitingForPreload={this.props.waitingForPreload}
                        defaultValue={this.state.fabricLine}
                        isValid={!!this.state.fabricLine || lowInfoFabricItems.includes(this.state.fabricAppliesTo)} />
                </div>
                <div className="col-6 mt-4 pr-0">
                    Material Preview
                    <Button color="secondary refresh-btn ml-5" onClick={() => this.refreshPreview()}> Refresh Preview</Button>
                    <div className="col-12 p-0 mt-2">
                        <div className="row m-0" style={this.state.viewLoading ? { display: "none" } : { height: 600 }}>
                            <OutfitPreview type="View" ref={ref => this.modalMannequin = ref} showFabricNames={false} showSaveScreenshot={false} showToggleImages={false} />
                        </div>
                        <div className="lds-circle" style={this.state.viewLoading ? { height: 600 } : { display: "none" }}><div></div></div>
                    </div>
                </div>
            </div>
        );
    }
}

class EditFabricFormState {
    fabricUrl: string = "";
    fabricNumber: string = "";
    fabricAppliesTo: OutfitItem = OutfitItem.None;
    fabricScale: number = 0.3;
    soldOut: boolean = false;
    viewLoading: boolean = false;
    primaryColor: Attr = null;
    primaryPattern: Attr = null;
    secondaryColor: Attr = null;
    secondaryPattern: Attr = null;
    material: Attr = null;
    fabricLine: Attr = null;
    previousFabricUrl: string = null;

    constructor(f: Fabric = null) {
        if (!f)
            return;

        this.previousFabricUrl = f.url;
        this.fabricUrl = f.url;
        this.fabricNumber = f.number;
        this.fabricAppliesTo = f.appliesTo;
        this.fabricScale = f.scale;
        this.soldOut = f.soldOut;
        this.primaryColor = AttrConsts.getAttribute(f.primaryColorId);
        this.primaryPattern = AttrConsts.getAttribute(f.primaryPatternId);
        this.secondaryColor = AttrConsts.getAttribute(f.secondaryColorId);
        this.secondaryPattern = AttrConsts.getAttribute(f.secondaryPatternId);
        this.material = AttrConsts.getAttribute(f.materialId);
        this.fabricLine = AttrConsts.getAttribute(f.fabricLineId);
    }
}

class EditFabricFormProps {
    fabric: Fabric;
    toggleAlertModal: (msg: string, type?: string) => void;
    waitingForPreload: boolean;
}