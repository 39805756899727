enum OutfitItem {
    None = 0,
    Jacket = 1,
    Pants = 2,
    Shirt = 4,
    Shoes = 8,
    ShoesImage = 32,
    TieImage = 64,
    WatchImage = 128,
    BagImage = 256,
    AccessoriesImage1 = 512,
    AccessoriesImage2 = 1024,
    AccessoriesImage3 = 2048
}
export default OutfitItem;

export const outfitItems: OutfitItem[] = [
    OutfitItem.Jacket,
    OutfitItem.Pants,
    OutfitItem.Shirt,
    OutfitItem.Shoes,
    OutfitItem.ShoesImage,
    OutfitItem.TieImage,
    OutfitItem.WatchImage,
    OutfitItem.BagImage,
    OutfitItem.AccessoriesImage1,
    OutfitItem.AccessoriesImage2,
    OutfitItem.AccessoriesImage3
];

export const lowInfoFabricItems = [
    OutfitItem.Shoes,
    OutfitItem.ShoesImage,
    OutfitItem.TieImage,
    OutfitItem.WatchImage,
    OutfitItem.BagImage,
    OutfitItem.AccessoriesImage1,
    OutfitItem.AccessoriesImage2,
    OutfitItem.AccessoriesImage3
];

export const materialItems = [
    OutfitItem.Jacket,
    OutfitItem.Pants,
    OutfitItem.Shirt,
    OutfitItem.Shoes
];

export const imageItems = [
    OutfitItem.ShoesImage,
    OutfitItem.TieImage,
    OutfitItem.WatchImage,
    OutfitItem.BagImage,
    OutfitItem.AccessoriesImage1,
    OutfitItem.AccessoriesImage2,
    OutfitItem.AccessoriesImage3
];

export function getName(item: OutfitItem): string {
    switch (item) {
        case OutfitItem.Jacket:
            return "Jacket";
        case OutfitItem.Pants:
            return "Pants";
        case OutfitItem.Shirt:
            return "Shirt";
        case OutfitItem.Shoes:
            return "Shoes";
        case OutfitItem.ShoesImage:
            return "Shoes (Preview)";
        case OutfitItem.TieImage:
            return "Tie";
        case OutfitItem.WatchImage:
            return "Watch";
        case OutfitItem.BagImage:
            return "Bag";
        case OutfitItem.AccessoriesImage1:
            return "Accessory 1";
        case OutfitItem.AccessoriesImage2:
            return "Accessory 2";
        case OutfitItem.AccessoriesImage3:
            return "Accessory 3";
    }
}

export function getKeyName(item: OutfitItem): string {
    switch (item) {
        case OutfitItem.Jacket:
            return "jacket";
        case OutfitItem.Pants:
            return "pants";
        case OutfitItem.Shirt:
            return "shirt";
        case OutfitItem.Shoes:
            return "shoes";
        case OutfitItem.ShoesImage:
            return "shoesImage";
        case OutfitItem.TieImage:
            return "tieImage";
        case OutfitItem.WatchImage:
            return "watchImage";
        case OutfitItem.BagImage:
            return "bagImage";
        case OutfitItem.AccessoriesImage1:
            return "accessoriesImage1";
        case OutfitItem.AccessoriesImage2:
            return "accessoriesImage2";
        case OutfitItem.AccessoriesImage3:
            return "accessoriesImage3";
    }
}

export function getFabricNumberPhrasing(item: OutfitItem): string {
    switch (item) {
        case OutfitItem.Shoes:
            return "Fabric Name";
        case OutfitItem.WatchImage:
        case OutfitItem.BagImage:
        case OutfitItem.AccessoriesImage1:
        case OutfitItem.AccessoriesImage2:
        case OutfitItem.AccessoriesImage3:
            return "Name";
        case OutfitItem.Jacket:
        case OutfitItem.Pants:
        case OutfitItem.Shirt:
        case OutfitItem.ShoesImage:
        case OutfitItem.TieImage:
        default:
            return "Fabric Number";
    }
}