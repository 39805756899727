import React from 'react';
import { Modal, Button } from "reactstrap";

export default class ConfirmModal extends React.Component<ConfirmModalProps> {
    closeWithDecision(decision: boolean) {
        this.props.onDecision(decision);
        this.props.toggle();
    }

    render() {
        return (
            <Modal size="md" isOpen={this.props.isVisible} toggle={() => this.closeWithDecision(false)}>
                <div className="row m-3">
                    <div className="col-12">
                        <p>Are you sure? </p>
                    </div>
                    <div className="col-6"></div>
                    <div className="col-3">
                        <Button className="w-100" onClick={() => this.closeWithDecision(true)} color="secondary" >Agree</Button>
                    </div>
                    <div className="col-3">
                        <Button className="w-100" onClick={() => this.closeWithDecision(false)} color="secondary" >Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

class ConfirmModalProps {
    isVisible: boolean;
    onDecision: (confirm: boolean) => void;
    toggle: () => void;
}