import OutfitItem from './OutfitItem';
import * as THREE from 'three';
import { MeshBasicMaterial, PlaneGeometry } from 'three';

export enum PinnedXPosition {
    Left,
    Center,
    Right
}

export enum PinnedYPosition {
    Top,
    Center,
    Bottom
}

export enum SizeStrategy {
    PreserveHeight,
    PreserveWidth,
    Square
}

export default class SizeAndPositionInfo {
    xPin: PinnedXPosition;
    yPin: PinnedYPosition;
    sizeStrategy: SizeStrategy;
    size: number;
    x: number;
    y: number;

    constructor(xPin: PinnedXPosition, yPin: PinnedYPosition, sizePreserved: SizeStrategy, size: number, x: number, y: number) {
        this.xPin = xPin;
        this.yPin = yPin;
        this.sizeStrategy = sizePreserved;
        this.size = size;
        this.x = x;
        this.y = y;
    }
}

export const sizeAndPositionInfo: Map<OutfitItem, SizeAndPositionInfo> = new Map<OutfitItem, SizeAndPositionInfo>([
    [ OutfitItem.WatchImage,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Top, SizeStrategy.PreserveWidth, 1.0, 2.2, 1.5) ], //
    [ OutfitItem.TieImage,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Top, SizeStrategy.PreserveHeight, 1.2, 1.6, -0.1) ], //
    [ OutfitItem.ShoesImage,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Bottom, SizeStrategy.PreserveWidth, 1.2, 1.6, -1.8) ], //
    [ OutfitItem.BagImage,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Top, SizeStrategy.PreserveHeight, 1.2, -1.6, 0.3) ], //
    [ OutfitItem.Jacket,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Center, SizeStrategy.Square, 0.7, -2.3, 1.8) ], //
    [ OutfitItem.Pants,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Center, SizeStrategy.Square, 0.7, 1.2, -1.1) ], //
    [ OutfitItem.Shirt,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Center, SizeStrategy.Square, 0.7, -1.6, 1.8) ], //
    [ OutfitItem.AccessoriesImage1,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Bottom, SizeStrategy.PreserveWidth, 1.4, -1.25, -0.8) ], //
    [ OutfitItem.AccessoriesImage2,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Bottom, SizeStrategy.PreserveWidth, 0.8, -1.25, -1.45) ], //
    [ OutfitItem.AccessoriesImage3,
    new SizeAndPositionInfo(PinnedXPosition.Center, PinnedYPosition.Bottom, SizeStrategy.PreserveWidth, 0.8, -1.25, -2.0) ] //
]);

export function createSizedImagePlane(oi: OutfitItem, beforeRender: (oi: OutfitItem) => void): THREE.Mesh {
    var info = sizeAndPositionInfo.get(oi);
    var mesh = new THREE.Mesh(new PlaneGeometry(info.size, info.size), new MeshBasicMaterial({color: "white"}));
    mesh.onBeforeRender = () => beforeRender(oi);
    mesh.frustumCulled = false;
    return mesh;
}