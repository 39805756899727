import React from 'react';
import Attr, { AttrConsts } from '../modules/Attr';
import Fabric, { getStoredFabrics, getStoredFabricTags } from '../modules/Fabrics';
import OutfitItem, { getName, lowInfoFabricItems, outfitItems } from '../modules/OutfitItem';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import SwatchItem from './SwatchItem';
import InfiniteScroll from 'react-infinite-scroller';
import '../../assets/css/SearchBox.css';

export default class SearchBox extends React.Component<SearchBoxProps, SearchBoxState>
{
    constructor(props: SearchBoxProps) {
        super(props);
        this.state = new SearchBoxState(props.initialSelection);
        this.fetchNextItems = this.fetchNextItems.bind(this);
    }

    static defaultProps = {
        allowedFabricLines: null,
        outfitItem: outfitItems.reduce((prev, cur) => prev + cur, OutfitItem.None),
        initialSelection: null,
        accessoryIndex: null
    };

    tagSearchBox: ReactTags;

    componentDidMount() {
        this.refilterFabrics();
    }

    setSelectedSwatch(fabric: Fabric) {
        this.setState({ selection: fabric });
        this.props.onSelectionChanged(fabric);
    }

    handleDelete(i: number) {
        if (i === -1)
            return false;

        const searchTags = [ ...this.state.searchTags ];
        searchTags.splice(i, 1);
        this.setState({ searchTags }, this.refilterFabrics);
    }

    handleAddition(tag: Tag) {
        const searchTags = [ ...this.state.searchTags ];
        searchTags.push(tag);
        this.setState({ searchTags }, this.refilterFabrics);
    }

    componentWillReceiveProps(nextProps: SearchBoxProps) {
        if ((nextProps.includeSoldOut !== this.props.includeSoldOut) || (nextProps.allowedFabricLines !== this.props.allowedFabricLines) || (nextProps.waitingForPreload !== this.props.waitingForPreload))
            this.refilterFabrics(nextProps);
    }

    refilterFabrics(nextProps: SearchBoxProps = null) {
        const matchesSearchTags = (f: Fabric): boolean => {
            // If we're searching by number, filter out none matches, or nothing if there's no search tags yet
            if (this.state.filterByFabricNumber)
                return this.state.searchTags.length === 0 || this.state.searchTags.some(t => t.id === f.id);

            // If we're filtering by attributes, ensure that every search tag has a corresponding attribute in the fabric
            var fabricAttrIds = f.getAttributeIds();
            return !this.state.searchTags.some(t => !fabricAttrIds.some(id => id === t.id));
        };

        if (!nextProps)
            nextProps = this.props;

        const filteredFabrics = getStoredFabrics().filter(f =>
            !f.isTemp
            && (nextProps.includeSoldOut || !f.soldOut)
            && ((f.appliesTo & this.props.outfitItem) !== 0)// || (f.appliesTo === OutfitItem.AccessoriesImage1 && (this.props.outfitItem === OutfitItem.AccessoriesImage2 || this.props.outfitItem === OutfitItem.AccessoriesImage3)))
            && (!nextProps.allowedFabricLines || lowInfoFabricItems.includes(this.props.outfitItem) || nextProps.allowedFabricLines.some(a => a.attributeId === f.fabricLineId))
            && matchesSearchTags(f)
        );

        var selectedItem = filteredFabrics.some(f => f === this.state.selection) ? this.state.selection : null;
        this.setState({ page: 0, selection: selectedItem, filteredFabrics, displayedFabrics: [] }, this.fetchNextItems);
    }

    fetchNextItems() {
        var displayedFabrics = this.state.displayedFabrics;
        var newResultsStart = this.state.page * this.props.itemsPerPage;
        var newResultsEnd = Math.min(newResultsStart + 1 + this.props.itemsPerPage, this.state.filteredFabrics.length - 1);
        for (var i = newResultsStart; i <= newResultsEnd; i++)
            displayedFabrics.push(this.state.filteredFabrics[ i ]);
        this.setState({ displayedFabrics, page: this.state.page + 1 });
    }

    render() {
        return (
            <div className="w-100 pb-2" key={"div" + getName(this.props.outfitItem)}>
                <div className="col-12" key={"col-12" + getName(this.props.outfitItem)}>
                    <h5 className="page-title mb-0 text-center">{getName(this.props.outfitItem)}</h5>
                    <div className="row pb-2">
                        <select name="filterType" className="form-control" defaultValue="byAttribute" onChange={e => this.setState({ filterByFabricNumber: e.target.value === "byFabricNumber" })}>
                            <option value="byAttribute">Filter by Attributes</option>
                            <option value="byFabricNumber">Filter by Fabric Number</option>
                        </select>
                    </div>
                    <div className="row">
                        <div className="w-100" >
                            <ReactTags
                                ref={ref => this.tagSearchBox = ref}
                                tags={this.state.searchTags}
                                suggestions={this.state.filterByFabricNumber ? getStoredFabricTags(this.props.outfitItem) : AttrConsts.getAttributeTags()}
                                handleDelete={this.handleDelete.bind(this)}
                                handleAddition={this.handleAddition.bind(this)}
                                placeholder={this.state.filterByFabricNumber ? "Fabric number" : "Attributes"}
                                allowNew={false}
                                autoresize={false} /><br />
                        </div>
                    </div>

                    <div id="scrollableDiv" style={{ height: 150, overflowY: "hidden", overflowX: "hidden", border: "1px solid #d3d3d3" }}>
                        <InfiniteScroll
                            loadMore={this.fetchNextItems}
                            hasMore={this.state.displayedFabrics.length < this.state.filteredFabrics.length}
                            loader={<div className="loader" key={0}><br />Loading ...</div>}
                            useWindow={false}
                            className="swatchBox row"
                            initialLoad={false}
                            threshold={250}>
                            {
                                this.state.filteredFabrics.map(fabric =>
                                    <SwatchItem
                                        id={fabric.id}
                                        soldOut={fabric.soldOut}
                                        name={fabric.number}
                                        url={fabric.url}
                                        appliesTo={fabric.appliesTo}
                                        scale={fabric.scale}
                                        key={fabric.id}
                                        zoom={this.props.zoom}
                                        selected={this.state.selection && this.state.selection.equals(fabric)}
                                        onClick={() => this.setSelectedSwatch(this.state.selection === fabric ? null : fabric)} />)
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        );
    }
}

class SearchBoxProps {
    waitingForPreload: boolean;
    includeSoldOut: boolean;
    onSelectionChanged: (fabric: Fabric) => void;
    allowedFabricLines: Attr[] = null;
    outfitItem: OutfitItem;
    zoom: number;
    itemsPerPage: number;
    initialSelection: Fabric = null;
}

class SearchBoxState {
    searchTags: Tag[] = [];
    selection: Fabric = null;
    filterByFabricNumber: boolean = false;
    filteredFabrics: Fabric[] = [];
    displayedFabrics: Fabric[] = [];
    page: number = 0;

    constructor(initialSelection: Fabric) {
        this.selection = initialSelection;
    }
}

export { SearchBox };