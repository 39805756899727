import React from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import PerOutfitItemFabrics from "../modules/PerOutfitItemFabrics";
import OutfitItem, { outfitItems, imageItems, materialItems, getName } from '../modules/OutfitItem';
import '../../assets/css/OutfitPreview.css';
import { TextureLoader, MeshBasicMaterial } from 'three';
import Fabric from '../modules/Fabrics';
import { saveAs } from 'file-saver';
import { SizeStrategy, createSizedImagePlane, sizeAndPositionInfo } from '../modules/SizeAndPositioning';

export default class OutfitPreview extends React.Component<OutfitPreviewProps, OutfitPreviewState> {
    constructor(props: OutfitPreviewProps, state: OutfitPreviewState) {
        super(props, state);
        this.state = new OutfitPreviewState();

        this.render = this.render.bind(this);
        this.recenterCamera = this.recenterCamera.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.onInterval = this.onInterval.bind(this);
        this.getTextureFromRecommendation = this.getTextureFromRecommendation.bind(this);
        this.setRecommendation = this.setRecommendation.bind(this);
        this.updateFabrics = this.updateFabrics.bind(this);
        this.saveScreenshot = this.saveScreenshot.bind(this);
        this.toggleImageVisibility = this.toggleImageVisibility.bind(this);
        this.getTextureFromUrl = this.getTextureFromUrl.bind(this);
        this.imageExists = this.imageExists.bind(this);
        this.beforePlaneRender = this.beforePlaneRender.bind(this);
        this.getTextDescriptionElements = this.getTextDescriptionElements.bind(this);
        this.getPlaneHidden = this.getPlaneHidden.bind(this);
    }

    static defaultProps = {
        style: {}
    };

    viewPortCanvas: HTMLCanvasElement;
    viewPort: HTMLDivElement;
    camera: THREE.PerspectiveCamera;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    clothesObjFileName: string = "clothes_smoothed.aaf";
    clothesObj: THREE.Object3D;
    controls: TrackballControls;
    planeObjDict: Map<OutfitItem, THREE.Mesh> = new Map<OutfitItem, THREE.Mesh>();

    private static clothesObjs: Map<string, THREE.Object3D> = new Map<string, THREE.Object3D>();
    private static _bodyObj: THREE.Object3D = null;
    static get bodyObj(): Promise<THREE.Object3D> {
        return new Promise<THREE.Object3D>(async (resolve, reject) => {
            if (!!OutfitPreview._bodyObj)
                resolve(OutfitPreview._bodyObj.clone(true));

            try {
                new OBJLoader()
                    .load('smoothed_headless_man_no_low_half.aaf', (obj) => {
                        // Load the body mat onto the body
                        var bodyMat = new THREE.MeshPhysicalMaterial({ color: new THREE.Color("white"), metalness: 0.3 });
                        obj.traverse(child => {
                            if (child instanceof THREE.Mesh)
                                child.material = bodyMat;
                        });

                        OutfitPreview._bodyObj = obj;
                        resolve(obj.clone(true));
                    });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    componentWillUnmount() {
        this.scene.dispose();
        this.controls.dispose();
        this.renderer.dispose();
    }

    // Load the body object (if necessary)
    private async getClothesObj(): Promise<THREE.Object3D> {
        // Load the body object itself, if it's not already been loaded
        if (!OutfitPreview.clothesObjs.has(this.clothesObjFileName))
            OutfitPreview.clothesObjs.set(this.clothesObjFileName, await new Promise<THREE.Object3D>(async (resolve, reject) => {
                try {
                    new OBJLoader().load(this.clothesObjFileName, obj => resolve(obj));
                } catch (ex) {
                    reject(ex);
                }
            }));

        return OutfitPreview.clothesObjs.get(this.clothesObjFileName).clone(true);
    }

    async componentDidMount() {
        // Set up the camera
        this.camera = new THREE.PerspectiveCamera(50, 1, 1, 10000);

        // Set up the scene
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0xffffff);

        // Add the body into the scene
        this.scene.add(await OutfitPreview.bodyObj);

        // Load the clothes object
        this.clothesObj = await this.getClothesObj();

        // Load a material onto the body
        this.scene.add(this.clothesObj);

        // Setup the planes we'll render our images onto
        for (let oi of OutfitPreview.allImageOutfitItems) {
            var planeMesh = createSizedImagePlane(oi, this.beforePlaneRender);
            this.scene.add(planeMesh);
            this.planeObjDict.set(oi, planeMesh);
        }

        // Set up the lighting of the scene
        this.scene.add(new THREE.AmbientLight(0x9b9b9b));
        const light = new THREE.DirectionalLight(0x9b9b9b);
        light.position.set(300, 300, 300);
        this.scene.add(light);

        // Set up the render
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.viewPortCanvas,
            antialias: true,
            precision: "lowp",
            powerPreference: "high-performance",
            preserveDrawingBuffer: true
        });

        this.renderer.setPixelRatio(window.devicePixelRatio);
        // this.renderer.setSize(400, 800, true);

        // Set up the controls to center the camera on the target object
        this.controls = new TrackballControls(this.camera, this.renderer.domElement);
        this.controls.rotateSpeed = 5.0;
        this.controls.zoomSpeed = 4;
        this.controls.panSpeed = 0.8;
        this.controls.staticMoving = true;
        this.controls.dynamicDampingFactor = 0.3;
        this.controls.keys = [ 65, 83, 68 ];
        this.controls.addEventListener('change', () => this.renderer.render(this.scene, this.camera));
        this.controls.enabled = true;
        this.controls.update();
        this.resetCamera();
        setInterval(this.onInterval, 10);
    }

    private imageExists(oi: OutfitItem): boolean {
        if (!this.state.recommendation)
            return false;
        const fabric = this.state.recommendation.get(oi);
        if (!fabric || !fabric.url)
            return false;
        return true;
    }

    private getPlaneHidden(oi: OutfitItem): boolean {
        return !this.state.recommendation || (this.state.recommendation.getId(oi) === -1);
    }

    private getPlaneScale(oi: OutfitItem): number[] {
        let plane = this.planeObjDict.get(oi);
        
        var hasImage = !!(plane.material as MeshBasicMaterial) && !!(plane.material as MeshBasicMaterial).map;
        var originalWidth = hasImage ? (plane.material as MeshBasicMaterial).map.image.width : 0;
        var originalHeight = hasImage ? (plane.material as MeshBasicMaterial).map.image.height : 0;

        let sizeAndPosInfo = sizeAndPositionInfo.get(oi);
        const originalDimsProvided = !!originalWidth && !!originalHeight && originalHeight > 0 && originalWidth > 0;
        var scaleX = sizeAndPosInfo.size;
        var scaleY = sizeAndPosInfo.size;
        if (sizeAndPosInfo.sizeStrategy === SizeStrategy.PreserveHeight && originalDimsProvided)
            scaleY = scaleX * originalHeight / originalWidth;
        else if (sizeAndPosInfo.sizeStrategy === SizeStrategy.PreserveWidth && originalDimsProvided)
            scaleX = scaleY * originalWidth / originalHeight;

        if (!materialItems.includes(oi) && !!this.state.recommendation && this.state.recommendation.getId(oi) !== -1) {
            scaleX *= this.state.recommendation.get(oi).scale;
            scaleY *= this.state.recommendation.get(oi).scale;
        }

        return [scaleX, scaleY];
    }

    private getPlaneX(oi: OutfitItem): number {
        let sizeAndPosInfo = sizeAndPositionInfo.get(oi);
        const offsetAccessoryParts = [OutfitItem.AccessoriesImage2, OutfitItem.AccessoriesImage3];
        if (!offsetAccessoryParts.includes(oi))
            return sizeAndPosInfo.x;

        var prevAccessories: OutfitItem[] = [];
        if ((oi === OutfitItem.AccessoriesImage2 || oi === OutfitItem.AccessoriesImage3) && !this.getPlaneHidden(OutfitItem.AccessoriesImage1))
            prevAccessories.push(OutfitItem.AccessoriesImage1);
        if (oi === OutfitItem.AccessoriesImage3 && !this.getPlaneHidden(OutfitItem.AccessoriesImage2))
            prevAccessories.push(OutfitItem.AccessoriesImage2);

        if (prevAccessories.length === 0)
            return sizeAndPosInfo.x;

        let minX: number = 1000000;
        for (let oi2 of prevAccessories) {
            let x = this.getPlaneX(oi2);
            x -= (this.getPlaneScale(oi2)[0] * sizeAndPositionInfo.get(oi2).size) / 2;
            minX = Math.min(minX, x);
        }
        return minX - (this.getPlaneScale(oi)[0] * sizeAndPositionInfo.get(oi).size) / 2;
    }

    private beforePlaneRender(oi: OutfitItem) {
        if (!this.planeObjDict.has(oi))
            return;

        let plane = this.planeObjDict.get(oi);
        if (this.getPlaneHidden(oi)) {
            (plane.material as MeshBasicMaterial).opacity = 0;
            (plane.material as MeshBasicMaterial).transparent = true;
            return;
        } else {
            (plane.material as MeshBasicMaterial).opacity = 1;
            (plane.material as MeshBasicMaterial).transparent = true;
        }

        plane.quaternion.copy(this.camera.quaternion);
        plane.position.copy(this.camera.position);
        plane.updateMatrix();

        let sizeAndPosInfo = sizeAndPositionInfo.get(oi);

        // Position
        let x = this.getPlaneX(oi);
        
        // Size
        var [scaleX, scaleY] = this.getPlaneScale(oi);

        // Application
        plane
            .translateZ(-10)
            .translateX(x)
            .translateY(sizeAndPosInfo.y)
            .scale.setX(scaleX).setY(scaleY);
    }

    resetCamera() {
        this.prevCameraPos = null;
        this.controls.update();
        this.controls.reset();
        this.handleResize();

        // Set up the camera
        const scaleFactor = 1000 / 500;
        this.camera.position.set(300 / scaleFactor, 300 / scaleFactor, 500 / scaleFactor);
        this.camera.zoom = this.props.type === "Recommendation" ? 1.5 : 1.8;
        this.camera.aspect = this.viewPort.clientWidth / this.viewPort.clientHeight;
        this.recenterCamera();
    }

    private recenterCamera() {
        var bb = new THREE.Box3();
        bb.setFromObject(this.clothesObj);
        bb.getCenter(this.controls.target);
        this.prevCameraPos = null;
        this.prevCameraRotation = null;
    }

    prevCameraPos: THREE.Vector3 = null;
    prevCameraRotation: THREE.Euler = null;
    private onInterval() {
        this.controls.update();
        this.camera.updateProjectionMatrix();
        this.renderer.render(this.scene, this.camera);
        if (!this.state.recommendation && this.props.type === "Recommendation")
            this.renderer.setSize(0, 0, true);
        else
            this.handleResize();
    }

    private async saveScreenshot() {
        try {
            if (!this.state.recommendation)
                return;
            var fileContent = this.renderer.domElement.toDataURL("image/jpg", 90);
            var fileContentPrefix = fileContent.substr(0, fileContent.indexOf('base64,') + 'base64,'.length);
            fileContent = fileContent.replace(fileContentPrefix, "");
            var body = {
                jacket: this.state.recommendation.getId(OutfitItem.Jacket),
                pants: this.state.recommendation.getId(OutfitItem.Pants),
                shirt: this.state.recommendation.getId(OutfitItem.Shirt),
                shoes: this.state.recommendation.getId(OutfitItem.Shoes),
                belt: this.state.recommendation.getId(OutfitItem.Shoes),
                bagImage: this.state.recommendation.getId(OutfitItem.BagImage),
                shoesImage: this.state.recommendation.getId(OutfitItem.ShoesImage),
                tieImage: this.state.recommendation.getId(OutfitItem.TieImage),
                watchImage: this.state.recommendation.getId(OutfitItem.WatchImage),
                accessoriesImage1: this.state.recommendation.getId(OutfitItem.AccessoriesImage1),
                accessoriesImage2: this.state.recommendation.getId(OutfitItem.AccessoriesImage2),
                accessoriesImage3: this.state.recommendation.getId(OutfitItem.AccessoriesImage3),
                imageBase64: fileContent
            };
            var resp = await fetch(`${process.env.REACT_APP_END_POINT_URL}/image/finish`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            var respText = await resp.text();
            if (!resp.ok) {
                console.error("Bad watermark request");
                console.error("request: ", body);
                console.error("response: ", respText);
                return;
            }

            var respBlob = fileContentPrefix + respText;
            // console.log(respBlob);
            saveAs(respBlob, "Outfit.jpg");
        } catch (e) {
            console.error(e);
        }
    }

    private handleResize() {
        if (!this.viewPort)
            return;

        try {
            this.renderer.setSize(this.viewPort.clientWidth, this.viewPort.clientHeight);
            this.camera.aspect = this.viewPort.clientWidth / this.viewPort.clientHeight;
            this.controls.handleResize();
            this.camera.updateProjectionMatrix();
        } catch (err) {
            console.error(err);
        }
    }

    private async getTextureFromRecommendation(): Promise<THREE.Texture> {
        return await this.getTextureFromUrl(`${ process.env.REACT_APP_END_POINT_URL }`
            + "/image"
            + "/" + (this.state.recommendation.get(OutfitItem.Jacket) ? this.state.recommendation.get(OutfitItem.Jacket).id : -1)
            + "/" + (this.state.recommendation.get(OutfitItem.Shirt) ? this.state.recommendation.get(OutfitItem.Shirt).id : -1)
            + "/" + (this.state.recommendation.get(OutfitItem.Pants) ? this.state.recommendation.get(OutfitItem.Pants).id : -1)
            + "/" + (this.state.recommendation.get(OutfitItem.Shoes) ? this.state.recommendation.get(OutfitItem.Shoes).id : -1));
    }

    private async getTextureFromUrl(url: string): Promise<THREE.Texture> {
        const loader = new TextureLoader();
        // loader.crossOrigin = "";

        return await new Promise<THREE.Texture>((resolve, reject) => loader.load(url, image => {
            try {
                image.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
                resolve(image);
            } catch(er) {
                reject(er);
            }
        }, null, e => {
            console.error(`Failed to load image from ${url}`);
            reject(e)
        }));
    }

    private static allImageOutfitItems = materialItems.concat(imageItems).filter(oi => oi !== OutfitItem.Shoes);
    setRecommendation(recommendation: PerOutfitItemFabrics) {
        if (!recommendation)
            recommendation = new PerOutfitItemFabrics();

        for (var oi of outfitItems)
            if (!recommendation.get(oi)) {
                var fabric = new Fabric();
                fabric.id = -1;
                recommendation.set(oi, fabric);
            }

        var materialsChanged = ((!!this.state.recommendation) !== (!!recommendation)) || materialItems.some(oi => recommendation.get(oi).id !== this.state.recommendation.get(oi).id);
        
        var changedImages = new Map<OutfitItem, boolean>();
        for (oi of OutfitPreview.allImageOutfitItems)
            changedImages.set(oi, (!!this.state.recommendation) !== (!!recommendation) || recommendation.get(oi).id !== this.state.recommendation.get(oi).id);

        this.setState({ recommendation }, async () => await this.updateFabrics(materialsChanged, changedImages));
    }

    private async updateFabrics(materialsChanged: boolean, changedImages: Map<OutfitItem, boolean>) {
        // Start loading
        this.setState({ loading: true });

        if (!this.clothesObj)
            return;

        // Load images
        for (let oi of OutfitPreview.allImageOutfitItems) {
            if (!changedImages.get(oi) 
            || !this.state.recommendation || this.state.recommendation.getId(oi) === -1
            || !this.planeObjDict.has(oi)
            || !this.planeObjDict.get(oi))
                continue;
            try {
                let fabricId = this.state.recommendation.getId(oi);
                let plane = this.planeObjDict.get(oi);

                console.log("loading texture");
                try {
                    var map = await this.getTextureFromUrl(process.env.REACT_APP_END_POINT_URL + '/fabrics/' + fabricId + '/image');
                } catch(err) {
                    console.error(`Failed to load texture for ${getName(oi)}:`, err);
                    continue;
                }

                console.log("loading alpha map");
                try {
                    var alphaMap = await this.getTextureFromUrl(process.env.REACT_APP_END_POINT_URL + '/fabrics/' + fabricId + '/bordermask');
                } catch(err) {
                    console.error(`Failed to load alpha texture ${getName(oi)}:`, err);
                }

                try {
                    if ((plane.material instanceof THREE.MeshBasicMaterial) && !!plane.material && !!plane.material.map) {
                        // plane.material.transparent = true;
                        // plane.
                        plane.material.map.dispose();
                        if (!!map)
                            plane.material.map = map;
                        plane.material.alphaMap.dispose();
                        if (!!alphaMap)
                            plane.material.alphaMap = alphaMap;
                    } else {
                        plane.material = new THREE.MeshBasicMaterial({ map: map, alphaMap: alphaMap, transparent: true, side: THREE.FrontSide, color: new THREE.Color("white"), blending: THREE.NormalBlending });
                    }
                } catch(e) {
                    console.error(`Error applying texture ${getName(oi)}:`, e)
                }
            } catch (err) {
                console.error(err);
            }
        }

        // Load texture itself
        if (materialsChanged) {
            var texture = await this.getTextureFromRecommendation();
            await new Promise((resolve, reject) => {
                this.clothesObj.traverse(child => {
                    if (child instanceof THREE.Mesh && child.name !== "man_body") {
                        if (child.material instanceof THREE.MeshLambertMaterial) {
                            child.material.map.dispose();
                            child.material.map = texture;
                        } else {
                            child.material = new THREE.MeshLambertMaterial({ map: texture });
                        }

                        resolve();
                        return;
                    }
                });

                reject();
            });
        }

        // End loading
        this.setState({ loading: false });
    }

    toggleImageVisibility() {
        for (var plane of this.planeObjDict.values())
            plane.visible = !plane.visible;
    }

    getTextDescriptionElements() {
        var components = [];
        for (var oi of outfitItems.filter(oi => oi !== OutfitItem.Shoes && this.state.recommendation.getId(oi) !== -1)) {
            components.push(
                <div style={{marginTop: 10, marginLeft: 10, marginRight: 10, marginBottom: 10}}>
                    <b>{getName(oi)}:{' '}</b> {this.state.recommendation.get(oi).number}
                </div>);
        }
        return components;
    }

    render() {
        return (
            <div className="col p-0 OutfitPreview" style={this.props.style}>
                <div className="ThreeJsViewPort" ref={ref => this.viewPort = ref} style={this.state.loading || !this.state.recommendation ? { display: "none" } : {}}>
                    <canvas className="ViewPort" ref={ref => this.viewPortCanvas = ref} />
                    {!this.state.showingText || !this.state.recommendation ? null : 
                    <div onClick={() => this.setState({showingText: !this.state.showingText})} style={{position: "absolute", bottom: "0px", top: "0px", left: "0px", right: "0px", backgroundColor: "rgba(169, 169, 169, 0.7)", textAlign: "center", justifyContent: "center", alignContent: "center", display: "flex"}}>
                        <div style={{backgroundColor: "white", display: "flex", flexDirection: "column", margin: "auto", borderRadius: "5%", minWidth: 100, minHeight: 40}}>
                            { this.getTextDescriptionElements() }
                        </div>
                    </div>
                    }
                </div>
                <div style={this.state.loading ? {} : { display: "none" }} className="lds-circle">
                    <div></div>
                </div>
                <div className="controlButtonPanel" style={this.state.loading || !this.state.recommendation ? {display: "none"} : {}}>
                    <div className="saveScreenshotButton" onClick={this.saveScreenshot} style={{ visibility: this.props.showSaveScreenshot ? "visible" : "collapse" }} />
                    <div className="toggleTextButton" onClick={() => this.setState({showingText: !this.state.showingText})} style={{ visibility: this.props.showFabricNames ? "visible" : "collapse" }} />
                    <div className="toggleImagesButton" onClick={this.toggleImageVisibility} style={{ visibility: this.props.showToggleImages ? "visible" : "collapse" }} />
                </div>
            </div>
        );
    }
}

class OutfitPreviewProps {
    style: React.CSSProperties;
    type: "Recommendation" | "View";
    showFabricNames: boolean;
    showSaveScreenshot: boolean;
    showToggleImages: boolean;
}

class OutfitPreviewState {
    loading: boolean = false;
    showingText: boolean = false;
    recommendation: PerOutfitItemFabrics = null;
}