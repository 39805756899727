import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ListGroup, ListGroupItem } from "reactstrap";
import OutfitItem from './modules/OutfitItem';
import Attr, { AttrConsts } from './modules/Attr';
import Fabric, { refreshFabrics, getStoredFabrics } from './modules/Fabrics';
import { refreshRules, getStoredRules } from './modules/Rules';
import AttrType from './modules/AttrType';
import EditFabricForm from './fabrics/EditFabricForm';
import SearchBox from './Swatches/SearchBox';

enum AddEditType {
    addFabric,
    editRules,
    editFabrics,
    addRule
}

export default class AddEditModal extends React.Component<AddEditModalProps, AddEditModalState> {
    constructor(props: AddEditModalProps, state: AddEditModalState) {
        super(props, state);
        this.state = new AddEditModalState();

        this.deleteFabric = this.deleteFabric.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.saveFabric = this.saveFabric.bind(this);
        this.addRecommendationRule = this.addRecommendationRule.bind(this);
    }

    editFabricSearchBox: SearchBox;
    editFabricControl: EditFabricForm;
    addFabricControl: EditFabricForm;

    async deleteRule(confirmation: boolean) {
        if (!confirmation)
            return;

        this.props.toggleAlertModal("Deleting", "loader");
        try {
            var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/rules/${ this.state.selectedRuleId }`, {
                method: "DELETE"
            });
            if (!response.ok)
                throw new Error("");

            await refreshRules();
            this.props.toggleAlertModal("Success");
            this.setState({ selectedRuleId: -1 });
        } catch (err) {
            this.props.toggleAlertModal("failure");
        }
    }

    async addRecommendationRule() {
        this.props.toggleAlertModal("Saving", "loader");

        var conditionAttribute = this.state.conditionAttribute;
        if (!conditionAttribute)
            conditionAttribute = AttrConsts.getAttributes(this.state.conditionAttributeType)[ 0 ];

        var consequenceAttribute = this.state.consequenceAttribute;
        if (!consequenceAttribute)
            consequenceAttribute = AttrConsts.getAttributes(this.state.consequenceAttributeType)[ 0 ];

        var body =
        {
            id: 0,
            negativeRule: !this.state.mustBe,
            conditionOutfitParts: OutfitItem.Jacket + OutfitItem.Pants + OutfitItem.Shirt,
            conditionAttribute: {
                id: conditionAttribute.attributeId,
                type: this.state.conditionAttributeType.type,
                name: conditionAttribute.name
            },
            recommendedOutfitParts: OutfitItem.Jacket + OutfitItem.Pants + OutfitItem.Shirt,
            recommendedAttribute: {
                id: consequenceAttribute.attributeId,
                type: this.state.consequenceAttributeType.type,
                name: consequenceAttribute.name
            }
        };

        try {
            var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/rules`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
            });

            if (!response.ok)
                throw new Error("Response invalid");

            refreshRules();
            this.props.toggleAlertModal("Success");
        } catch (err) {
            this.props.toggleAlertModal("failure");
            return;
        }

        this.setState({ mustBe: false, conditionAttributeType: AttrType.primaryColor, consequenceAttributeType: AttrType.secondaryColor });
    }

    render() {
        return (
            <Modal size="xl" isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader>Add / Edit</ModalHeader>
                <ModalBody>
                    <div className="container-fluid row m-0">
                        <div className="row pb-3 col-12">
                            <div className="col-12">
                                Select Type
				                <select name="filterType" className="form-control" value={this.state.addType} onChange={e => this.setState({ addType: Number(e.target.value) })}>
                                    <option value={AddEditType.addFabric}>Add Fabric</option>
                                    <option value={AddEditType.addRule}>Add Rule</option>
                                    <option value={AddEditType.editFabrics}>Edit Fabrics</option>
                                    <option value={AddEditType.editRules}>View / Delete Rules</option>
                                </select>
                            </div>
                        </div>
                        <hr />
                        {this.renderVariableUiParts()}
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    async deleteFabric() {
        if (!this.state.selectedFabric) {
            this.props.toggleAlertModal("Select fabric");
            return;
        }

        this.props.toggleAlertModal("Deleting", "loader");
        try {
            var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/fabrics/${ this.state.selectedFabric.id }`, {
                method: "DELETE"
            });
            if (!response.ok)
                throw new Error();

            await refreshFabrics();
            this.setState({ selectedFabric: null });
            this.editFabricSearchBox.refilterFabrics();
            this.props.onFabricChanges();
            this.props.toggleAlertModal("Success");
        } catch (err) {
            this.props.toggleAlertModal("Failure");
        }
    }

    async saveFabric() {
        var control = this.state.addType === AddEditType.addFabric ? this.addFabricControl : this.editFabricControl;
        if (!control) {
            console.error("No handle to edit fabric control");
            return;
        }

        if (!control.fabricIsValid()) {
            this.props.toggleAlertModal("Fabric invalid, correct errors before proceeding");
            return;
        }

        var fabric = control.getFabric();
        try {
            if (this.state.addType === AddEditType.addFabric) {
                var resp = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/fabrics`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            id: 0,
                            soldOut: fabric.soldOut,
                            number: fabric.number,
                            appliesTo: fabric.appliesTo,
                            scale: fabric.scale,
                            isTemp: false
                        }),
                        headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
                    });
                if (!resp.ok)
                    throw new Error();
                fabric.id = await resp.json();
            }

            var response = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/fabrics/${ fabric.id }`,
                {
                    method: "PATCH",
                    body: JSON.stringify(fabric),
                    headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
                });
            if (!response.ok)
                throw new Error();

            await refreshFabrics();

            // Refresh our search box swatches
            if (!!this.editFabricSearchBox)
                this.editFabricSearchBox.refilterFabrics();

            // Notify the parent of the changes to fabrics
            this.props.onFabricChanges();

            // Select the newly created fabric, if we're creating a new one
            if (this.state.addType === AddEditType.addFabric) {
                var matchingFabrics = getStoredFabrics().filter(f => f.number === fabric.number);
                if (matchingFabrics.length > 0)
                    this.setState({ addType: AddEditType.editFabrics, selectedFabric: matchingFabrics[ 0 ] });
            }
            this.props.toggleAlertModal("Saved successfully");
        } catch {
            this.props.toggleAlertModal("Issue saving fabric");
        }
    }

    renderVariableUiParts() {
        var components = [];
        if (this.state.addType === AddEditType.editFabrics) {
            components.push(
                <EditFabricForm key="edit" ref={ref => this.editFabricControl = ref} toggleAlertModal={(msg, type = "Alert") => this.props.toggleAlertModal(msg, type)} waitingForPreload={this.props.waitingForPreload} fabric={this.state.selectedFabric}>
                    <SearchBox ref={ref => this.editFabricSearchBox = ref} onSelectionChanged={f => this.setState({ selectedFabric: f }, () => this.editFabricControl.updateModelOutfit())} includeSoldOut={true} waitingForPreload={this.props.waitingForPreload} zoom={this.props.zoom} itemsPerPage={this.props.itemsPerPage} initialSelection={this.state.selectedFabric} />
                </EditFabricForm>
            );
        } else if (this.state.addType === AddEditType.addFabric) {
            components.push(
                <EditFabricForm key="add" ref={ref => this.addFabricControl = ref} toggleAlertModal={(msg, type = "Alert") => this.props.toggleAlertModal(msg, type)} waitingForPreload={this.props.waitingForPreload} />
            );
        } else if (this.state.addType === AddEditType.editRules) {
            components.push(
                <div className="row pb-2 w-100">
                    <div className="col-12">
                        <ListGroup className="rulesList">
                            {
                                getStoredRules().map(rule =>
                                    <ListGroupItem tag="button" action onClick={() => this.setState({ selectedRuleId: rule.id })} value={rule.id} key={rule.id}>
                                        {`If a fabric ${ rule.conditionAttribute.type.displayName.toLowerCase() } is ${ rule.conditionAttribute.name.toLowerCase() }, `
                                            + `then all other fabrics ${ rule.recommendedAttribute.type.displayName.toLowerCase() } ${ rule.negativeRule ? "must not be " : "must be " }`
                                            + `${ rule.recommendedAttribute.name.toLowerCase() }`}
                                    </ListGroupItem>
                                )
                            }
                        </ListGroup>
                    </div>
                </div>);
        } else if (this.state.addType === AddEditType.addRule) {
            var allowedAttributeTypes = [ AttrType.primaryColor, AttrType.primaryPattern, AttrType.secondaryColor, AttrType.secondaryPattern, AttrType.material ];
            components.push(
                <div className="row pb-3">
                    <div className="mt-1 pl-2 pt-1 col-1">
                        If fabric
                    </div>
                    <div className="col-4 pb-2">
                        <select name="conditionType" className="form-control" value={this.state.conditionAttributeType.type} onChange={e => this.setState({ conditionAttributeType: new AttrType(e.target.value) })}>
                            {allowedAttributeTypes.map(type => <option value={type.camelCaseName}>{type.displayName}</option>)}
                        </select>
                    </div>
                    <div className="mt-1 pt-1">
                        is
                    </div>
                    <div className="col-4">
                        <select name="condition" className="form-control" value={!this.state.conditionAttribute ? 0 : this.state.conditionAttribute.attributeId} onChange={e => this.setState({ conditionAttribute: AttrConsts.getAttribute(Number(e.target.value)) })}>
                            {AttrConsts.getAttributes(this.state.conditionAttributeType).map(attr => <option value={attr.attributeId}>{attr.name.toLowerCase()}</option>)}
                        </select>
                    </div>
                    <div className="mt-1 col-2"> then other </div>
                    <div className="mt-1 pl-3 ml-1 col-1"> fabric </div>
                    <div className="col-4 pl-2">
                        <select name="consequenceType" className="form-control" value={this.state.consequenceAttributeType.type} onChange={e => this.setState({ consequenceAttributeType: new AttrType(e.target.value) })}>
                            {allowedAttributeTypes.map(type => <option value={type.camelCaseName}>{type.displayName}</option>)}
                        </select>
                    </div>
                    <div className="col-2 p-0">
                        <select name="mustBe" className="form-control" value={Number(this.state.mustBe)} onChange={e => this.setState({ mustBe: Boolean(e.target.value) })}>
                            <option value={Number(true)}>must be</option>
                            <option value={Number(false)}>must not be</option>
                        </select>
                    </div>
                    <div className="col-4 ml-2">
                        <select name="consequence" className="form-control" value={!this.state.consequenceAttribute ? 0 : this.state.consequenceAttribute.attributeId} onChange={e => this.setState({ consequenceAttribute: AttrConsts.getAttribute(Number(e.target.value)) })}>
                            {AttrConsts.getAttributes(this.state.consequenceAttributeType).map(attr => <option value={attr.attributeId}>{attr.name.toLowerCase()}</option>)}
                        </select>
                    </div>
                </div>);
        }

        var primaryButtons = [];
        switch (this.state.addType) {
            case AddEditType.addRule:
                primaryButtons.push(this.renderPrimaryButton(this.addRecommendationRule, "Add Recommendation Rule"));
                break;
            case AddEditType.editRules:
                primaryButtons.push(this.renderPrimaryButton(() => this.props.getConfirmation(this.deleteRule), "Delete"));
                break;
            case AddEditType.editFabrics:
                primaryButtons.push(this.renderPrimaryButton(this.saveFabric, "Save"));
                primaryButtons.push(this.renderPrimaryButton(() => this.props.getConfirmation(this.deleteFabric), "Delete"));
                break;
        }

        components.push(
            <div className="col-12 row pb-1 pt-3" key={14}>
                <div className="col-6 text-right"> {primaryButtons} </div>
                <div className="col-6">
                    {this.state.addType === AddEditType.addFabric ? this.renderPrimaryButton(this.saveFabric, "Add") : null}
                    <Button color="secondary ml-3" onClick={this.props.toggle}>
                        {this.state.addType !== AddEditType.editRules && this.state.addType !== AddEditType.editFabrics ? "Cancel" : "Return"}
                    </Button>
                </div>
            </div>
        );

        return components;
    }

    renderPrimaryButton(onClick, buttonText: string): JSX.Element {
        return (<Button color="secondary ml-3" onClick={onClick}> {buttonText} </Button>);
    }
}

class AddEditModalState {
    addType: AddEditType = AddEditType.addFabric;
    conditionAttributeType: AttrType = AttrType.primaryColor;
    conditionAttribute: Attr = null;
    consequenceAttributeType: AttrType = AttrType.primaryColor;
    consequenceAttribute: Attr = null;
    selectedFabric: Fabric = null;
    mustBe: boolean = true;
    selectedRuleId: number = -1;
}

class AddEditModalProps {
    isOpen: boolean;
    toggle: () => void;
    toggleAlertModal: (msg: string, type?: string) => void;
    getConfirmation: (callback: (confirmation: boolean) => void) => void;
    zoom: number;
    waitingForPreload: boolean;
    itemsPerPage: number;
    onFabricChanges: () => void;
}