import { Field, Type, deserialize } from 'serialize-ts';
import OutfitItem from './OutfitItem';
import { Tag } from 'react-tag-autocomplete';

export default class Fabric {
    @Field()
    @Type(Number)
    id: number = 0;

    @Field()
    @Type(String)
    number: string = '';

    @Field()
    @Type(Boolean)
    soldOut: boolean = false;

    @Field()
    @Type(String)
    url: string = '';

    @Field()
    @Type(Number)
    scale: number = 0.3;

    @Field()
    @Type(Boolean)
    isTemp: boolean = false;

    @Field()
    @Type(Number)
    appliesTo?: OutfitItem = OutfitItem.None;

    @Field()
    @Type(Number)
    fabricLineId?: number = null;

    @Field()
    @Type(Number)
    primaryColorId?: number = null;

    @Field()
    @Type(Number)
    primaryPatternId?: number = null;

    @Field()
    @Type(Number)
    secondaryColorId?: number = null;

    @Field()
    @Type(Number)
    secondaryPatternId?: number = null;

    @Field()
    @Type(Number)
    materialId?: number = null;

    getAttributeIds(): number[] {
        return [ this.primaryColorId, this.primaryPatternId, this.secondaryColorId, this.secondaryPatternId, this.materialId, this.fabricLineId ].filter(id => !!id);
    }

    equals(other: Fabric): boolean {
        return !!other && this.id === other.id;
    }

    toTag(): Tag {
        return {
            id: this.id,
            name: `Fabric Number: ${ this.number }`,
            disabled: false
        };
    }
}

let storedFabrics: Fabric[];
export async function refreshFabrics(): Promise<void> {
    var fabricResponses = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/fabrics`, {
        method: "PUT",
        body: JSON.stringify([]),
        headers: [ [ "accept", "text/plain" ], [ "Content-Type", "application/json" ] ]
    });
    const jsonFabrics = await fabricResponses.json();
    storedFabrics = jsonFabrics.map(jf => deserialize(jf, Fabric));
}

export function getStoredFabricTags(forItem: OutfitItem): Tag[] {
    return storedFabrics
        .filter(f => (f.appliesTo & forItem) !== 0)
        .map(function (f: Fabric): Tag {
            return {
                name: f.number,
                id: f.id,
                disabled: false
            };
        });
}

export function getStoredFabrics(): Fabric[] {
    return storedFabrics;
}