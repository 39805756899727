import React from 'react';
import PerOutfitItemFabrics from "../modules/PerOutfitItemFabrics";
import OutfitPreview from './OutfitPreview';
import '../../assets/css/RecommendationPreviewer.css';

export default class RecommendationCarousel extends React.Component<RecommendationCarouselProps, RecommendationCarouselState> {
    constructor(props, state) {
        super(props, state);
        this.state = {
            selection: null,
            recommendations: []
        };
    }
    recommendationPreviews: OutfitPreview[] = [];

    static defaultProps = {
        style: {}
    };

    resetCamera() {
        for (var preview of this.recommendationPreviews)
            preview.resetCamera();
    }

    setRecommendations(recommendations: PerOutfitItemFabrics[]) {
        // this.setState({recommendations});
        this.recommendationPreviews.forEach((preview, index) => {
            var hasValidRec = !!recommendations && recommendations.length > index;
            preview.setRecommendation(hasValidRec ? recommendations[index] : null);
            if (hasValidRec)
                preview.resetCamera();
        });
    }

    getPreviewDiv(index: number) {
        return <OutfitPreview style={this.props.style} type="View" key={"rec_preview_" + index} ref={ref => this.recommendationPreviews[index] = ref} showFabricNames={true} showSaveScreenshot={true} showToggleImages={true} />
    }

    render() {
        var previews: JSX.Element[] = [];
        for (var i = 0; i < parseInt(process.env.REACT_APP_RECOMMENDATION_COUNT); i++)
            previews.push(this.getPreviewDiv(i));

        if (previews.length === 1)
            return previews[0];

        return(
            <div className="HorizontalScrollContainer" style={this.props.style}>
                <div className="row h-100 w-100">
                    {previews}
                </div>
            </div>
        );
    }
}

class RecommendationCarouselProps {
    style: React.CSSProperties;
}

class RecommendationCarouselState {
    selection: PerOutfitItemFabrics;
    recommendations: PerOutfitItemFabrics[];
}