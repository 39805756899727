import React from 'react';
import { Modal, Button } from "reactstrap";

export default class LoginModal extends React.Component<LoginModalProps, LoginModalState> {
    constructor(props: LoginModalProps, state: LoginModalState) {
        super(props, state);
        this.state = new LoginModalState();
    }

    async confirmCredentials(): Promise<boolean> {
        var resp = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/user/${ this.state.username }/${ this.state.password }`, { method: "GET" });
        return resp.ok;
    }

    async finalizeLogin() {
        if (!await this.confirmCredentials()) {
            this.props.toggleAlertModal("Login Failed!");
            return;
        }

        this.props.onLogin();
        this.props.toggle();
    }

    render() {
        return (
            <Modal size="md" isOpen={this.props.isVisible} toggle={this.props.toggle}>
                <div className="row m-3">
                    <div className="col-12">
                        <p>Username </p>
                        <input type="text" name="username" className="form-control" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} required />
                        <p>Password</p>
                        <input type="text" name="password" className="form-control" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} required />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-3">
                        <Button className="w-100" onClick={() => this.finalizeLogin()} color="secondary">Login</Button>
                    </div>
                    <div className="col-3">
                        <Button className="w-100" onClick={this.props.toggle} color="secondary">Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

class LoginModalProps {
    isVisible: boolean;
    onLogin: () => void;
    toggle: () => void;
    toggleAlertModal: (msg: string, type?: string) => void;
}

class LoginModalState {
    username: string ;//= "admin";
    password: string ;//= "VerySecure089704";
}