import React from 'react';
import { Modal, Button } from "reactstrap";

export default class AlertModal extends React.Component<AlertModalProps> {
    render() {
        return (
            <Modal size="md" isOpen={this.props.policyAlertModal} toggle={() => this.props.toggle()}>
                <div className="row m-3">
                    <div className="col-12">
                        <p style={{ display: 'inline-block' }}>{this.props.modalText}</p>
                        {this.props.modalType !== "Alert" ?
                            <div id="wave">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </div>
                            : null}
                    </div>
                    <div className="col-9"></div>
                    {this.props.modalType === "Alert" ?
                        <div className="col-3">
                            <Button className="w-100" onClick={() => this.props.toggle()} color="secondary"> OK </Button>
                        </div>
                        : null}
                </div>
            </Modal>
        );
    }
}

class AlertModalProps {
    policyAlertModal: boolean;
    modalText: string;
    modalType: String;
    toggle: (msg?: string) => void;
}