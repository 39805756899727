import { Field, Type, deserialize } from 'serialize-ts';
import Attr from './Attr';

class Rule {
    @Field()
    @Type(Number)
    id: number = 0;

    @Field()
    @Type(Boolean)
    negativeRule = false;

    @Field()
    @Type(Number)
    conditionOutfitParts = 0;

    @Field()
    @Type(Number)
    recommendedOutfitParts = 0;

    conditionAttribute: Attr;

    recommendedAttribute: Attr;
}

var storedRules: Rule[] = [];
async function refreshRules(): Promise<void> {
    //Get all rules
    var ruleResponses = await fetch(`${ process.env.REACT_APP_END_POINT_URL }/rules`, { method: "GET" });
    var jsonRules = await ruleResponses.json();
    storedRules = [];
    for (var jsonRule of jsonRules) {
        const rule = deserialize(jsonRule, Rule);
        rule.conditionAttribute = deserialize(jsonRule.conditionAttribute, Attr);
        rule.recommendedAttribute = deserialize(jsonRule.recommendedAttribute, Attr);
        storedRules.push(rule);
    }
}

function getStoredRules(): Rule[] {
    return storedRules;
}

export { Rule, getStoredRules, refreshRules };