import AttrType from '../modules/AttrType';
import React from 'react';
import Attr, { AttrConsts } from '../modules/Attr';

export default class AttributeDropdownEditor extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props, state);
        this.state = new State();
    }

    static defaultProps = {
        isValid: true,
        defaultValue: null
    };

    onValueChanged(id: number) {
        this.setState({ selectedAttrId: id }, () => this.props.onChange(AttrConsts.getAttribute(id)));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.waitingForPreload !== this.props.waitingForPreload)
            this.forceUpdate();
    }

    render() {
        return (
            <div className="col-12">
                {this.props.type.displayName}
                <select name={this.props.type.camelCaseName} className={"form-control " + (this.props.isValid ? "" : "errorValue")} value={!this.props.defaultValue ? 0 : this.props.defaultValue.attributeId} onChange={e => this.onValueChanged(Number(e.target.value))}>
                    <option value={0}>...</option>
                    {AttrConsts.getAttributes(this.props.type).map(attribute =>
                        <option key={`${ attribute.attributeId }_${ attribute.type.camelCaseName }`} value={attribute.attributeId}>{attribute.name}</option>
                    )}
                </select>
            </div>
        );
    }
}

class Props {
    type: AttrType;
    isValid: boolean;
    onChange: (a: Attr) => void;
    waitingForPreload: boolean;
    defaultValue: Attr;
}

class State {
    selectedAttrId: number = 0;
}