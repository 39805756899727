import { Serializer } from 'serialize-ts';

export default class AttrType extends Object {
    type: string;
    displayName: string;
    camelCaseName: string;

    static primaryColor = new AttrType({
        type: "PrimaryColor",
        displayName: "Primary Color",
        camelCaseName: "primaryColor",
    });
    static secondaryColor = new AttrType({
        type: "SecondaryColor",
        displayName: "Secondary Color",
        camelCaseName: "secondaryColor",
    });
    static primaryPattern = new AttrType({
        type: "PrimaryPattern",
        displayName: "Primary Pattern",
        camelCaseName: "primaryPattern",
    });
    static secondaryPattern = new AttrType({
        type: "SecondaryPattern",
        displayName: "Secondary Pattern",
        camelCaseName: "secondaryPattern",
    });
    static material = new AttrType({
        type: "Material",
        displayName: "Material",
        camelCaseName: "material",
    });
    static fabricLine = new AttrType({
        type: "FabricLine",
        displayName: "fabricLine",
        camelCaseName: "fabricLine",
    });
    constructor(props: Partial<AttrType> | string) {
        super(typeof props === 'string' ? null : props);
        if (typeof props !== 'string') {
            this.camelCaseName = props.camelCaseName;
            this.displayName = props.displayName;
            this.type = props.type;
            return;
        }

        // var type: AttrType;
        switch (props.toLowerCase().replace(' ', '')) {
            case 'primarycolor':
                return AttrType.primaryColor;
            case 'secondarycolor':
                return AttrType.secondaryColor;
            case 'primarypattern':
                return AttrType.primaryPattern;
            case 'secondarypattern':
                return AttrType.secondaryPattern;
            case 'material':
                return AttrType.material;
            case 'farbicline':
                return AttrType.fabricLine;
            default:
                throw props;
        }
    }
}

export class AttrTypeSerializer implements Serializer<AttrType> {
    serialize(model: AttrType): string {
        return model.type;
    }

    deserialize(type: string): AttrType {
        switch (type.toLowerCase().replace(' ', '')) {
            case 'primarycolor':
                return AttrType.primaryColor;
            case 'secondarycolor':
                return AttrType.secondaryColor;
            case 'primarypattern':
                return AttrType.primaryPattern;
            case 'secondarypattern':
                return AttrType.secondaryPattern;
            case 'material':
                return AttrType.material;
            case 'fabricline':
                return AttrType.fabricLine;
            default:
                throw new Error(`${ type } not regonized as attr type`);
        }
    }
}