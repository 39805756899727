import PerOutfitItemData from "./PerOutfitItemData";
import Fabric from './Fabrics';
import OutfitItem, { outfitItems } from './OutfitItem';

export default class PerOutfitItemFabrics extends PerOutfitItemData<Fabric> {
    constructor() {
        super(null);
        this.checkDataEqualityFunction = (me, other) => !!me && me.equals(other);
    }

    static createInstanceForSingleItemPreview(oi: OutfitItem, fabric: Fabric): PerOutfitItemFabrics {
        var obj = new PerOutfitItemFabrics();
        for (var i of outfitItems)
            if ((oi & i) !== 0)
                obj.set(i, fabric);
        return obj;
    }

    getId(oi: OutfitItem) {
        return this.has(oi) && !!this.get(oi) ? this.get(oi).id : -1;
    }
}
