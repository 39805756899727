import React from 'react';
import { imageItems } from '../modules/OutfitItem';

export default class SwatchItem extends React.Component<SwatchItemProps, SwatchItemState> {
    constructor(props: SwatchItemProps, state: SwatchItemState) {
        super(props, state);
        this.state = { hover: false };
    }

    render() {
        return (
            <div className="swatchFrame">
                <div className={"swatch" + (this.props.selected ? " Selected" : "") + (this.state.hover && !this.props.selected ? " Hover" : "")}
                    key={this.props.id}
                    onMouseOver={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                    onClick={this.props.onClick}>
                    <div className="TextBackdrop" style={{ backgroundSize: imageItems.includes(this.props.appliesTo) || (this.props.zoom === 100) ? 'cover' : `${ this.props.zoom }%`, backgroundImage: 'url(' + `${ process.env.REACT_APP_END_POINT_URL }/image/getFabricImg/` + this.props.id + ')' }} />
                </div>
                <h6 className="swatchText">{this.props.name}</h6>
            </div>
        );
    }
}


class SwatchItemState {
    hover: boolean;
}

class SwatchItemProps {
    id: number;
    soldOut: boolean;
    name: string;
    url: string;
    appliesTo: number;
    scale: number;
    onClick: () => void;
    selected: boolean;
    zoom?: number;
}