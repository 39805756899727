import OutfitItem, { outfitItems } from './OutfitItem';

export default class PerOutfitItemData<T> extends Map<OutfitItem, T> {
    constructor(entries?: readonly (readonly [ OutfitItem, T ])[] | null) {
        super(entries);

        for (let oi of outfitItems)
            if (!this.has(oi))
                this.set(oi, undefined);
    }

    static withDefaultValue<T>(defaultValue: T): PerOutfitItemData<T> {
        return new PerOutfitItemData<T>(outfitItems.map(oi => [ oi, defaultValue ]));
    }

    checkDataEqualityFunction: (me: T, other: T) => boolean = (me, other) => me === other;
    equals(other: PerOutfitItemData<T>) {
        if (!other)
            return false;
        for (var [ key, value ] of this)
            if (!this.checkDataEqualityFunction(value, other.get(key)))
                return false;
        return true;
    }
}
